import React from "react";
import Title from "../Titleholder/Title";
import './Sidebar.css';
import { Link } from "react-router-dom";

// Import images at the top
import climateChangeImage from "../../asset/climate-change1.webp";
import gdpImage from "../../asset/gdp.webp";
import droneSoilImage from "../../asset/Drone-soil.webp";
import searchImage from "../../asset/search.png";

const Sidebar = () => {
  const sidebarr = [
    {
      img: climateChangeImage,
      desc: "Climate Change Threatens Vital Soil Biodiversity | Impact on Soil-Dwelling Species",
      authore: 'Amarben Patni',
      date: '18 July 24',
      linkkk: "https://precisiongrowindia.blogspot.com/impact-of-climate-change-on-soil-dwelling-species"
    },
    {
      img: gdpImage,
      desc: "Understanding Green GDP And Its Implications For Sustainable Development",
      authore: 'Amarben Patni',
      date: '05 April 24',
      linkkk: "https://precisiongrowindia.blogspot.com/green-gdp-sustain"
    },
    {
      img: droneSoilImage,
      desc: "Drone Soil Analysis: Multispectral Remote Sensing for Soil Mapping",
      authore: 'Amarben Patni',
      date: '05 April 24',
      linkkk: "https://precisiongrowindia.blogspot.com/drone-soil-analysis-multispectral"
    }
  ];

  return (
    <>
      <div className="rounded sidebar-outer">
        <div className="shadow rounded p-3 sidebar-div">
          <Title title='Search' />
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Type here..."
            />
            <button type="submit" className="search-button">
              <img src={searchImage} alt="Search" />
            </button>
          </div>
        </div>
        <div className="shadow rounded p-3 recent-info-outer mt-4 sidebar-div">
          <Title title='Recent Post' />
          <ul style={{ listStyle: 'none', padding: '0' }}>
            {sidebarr.map((sidebar1, index) => (
              <li key={index} className={`my-4 ${index === 0 ? "active" : ""}`}>
                <div className="pt-2 col-12 row recent-info-inner">
                  <div className="col-md-4 col-4 d-none d-md-block">
                    <img
                      src={sidebar1.img} className="w-100"
                      alt={sidebar1.desc}
                    />
                  </div>
                  <div className="col-md-8 col-12">
                    <h6>
                      <Link
                        to={sidebar1.linkkk}
                        target="_blank"
                      >
                        {sidebar1.desc}
                      </Link>
                    </h6>
                    <div className="ad-info">
                      <i className="fa-regular fa-user mx-1"></i>{" "}
                      <span className="fw-bold">{sidebar1.authore}</span>
                      <i className="fa-solid fa-calendar-days mx-2"></i>
                      <span className="fw-bold">{sidebar1.date}</span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
