import React from 'react';
import './Iconslider.css';

// Import images at the top
import part1 from "../../asset/part-1.webp";
import part3 from "../../asset/part-3.webp";
import part4 from "../../asset/part-4.webp";
import part5 from "../../asset/part-5.webp";
import part6 from "../../asset/part-6.webp";
import part7 from "../../asset/part-7.webp";
import part8 from "../../asset/part-8.webp";
import part9 from "../../asset/part-9.webp";
import part10 from "../../asset/part-10.webp";
import part11 from "../../asset/part-11.webp";
import part12 from "../../asset/part-12.webp";
import part13 from "../../asset/part-13.webp";
import part14 from "../../asset/part-14.webp";
import part15 from "../../asset/part-15.webp";

const Iconslider = () => {
    const iconslider = [
        { img: part1 },
        { img: part3 },
        { img: part4 },
        { img: part5 },
        { img: part6 },
        { img: part7 },
        { img: part8 },
        { img: part9 },
        { img: part10 },
        { img: part11 },
        { img: part12 },
        { img: part13 },
        { img: part14 },
        { img: part15 }
    ];

    return (
        <div className="icon-slider">
            <div className="icon-slide-track">
                {iconslider.map((slidee, index) => (
                    <div key={index} className={`icon-slide ${index === 0 ? "active" : ""}`}>
                        <img src={slidee.img} height="100" width="200" alt={`Icon ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Iconslider;
