import React, { useState } from "react";
import Title from "../Titleholder/Title";
import { Link } from "react-router-dom";
import "./Blog.css";

// Import images
import Novemberimg from "../../asset/November-magzine.jpg";
import octoberImage from "../../asset/October-Magzine.jpeg";
import SeptemberImage from "../../asset/sept-coverpg.jpg";
import AugustImage from "../../asset/August_magzinE.webp";
import JulyImage from "../../asset/july_magazine.jpg";
import juneImage from "../../asset/june-banner.jpg";
import octoberMagzine from '../../asset/October-magazine.pdf';
import septMagzine from '../../asset/September.pdf';
import augustMagzine from '../../asset/August-magazine.pdf';
import julyMagzine from '../../asset/july.pdf';
import june from '../../asset/june.pdf';
import November from '../../asset/November-magazine.pdf';
import December from '../../asset/December.pdf';
import Decemberimg from '../../asset/December-img.webp';

// Sample data
const allPosts = [
  {
    title: "Driving Innovation And Sustainability In Agriculture",
    link: December,
    image: Decemberimg,
    type: "Magazine",
    published: "Tech Vist IT Pvt Ltd",
    month: "December",
    mode: "Print & Online",
  },
  {
    title: "Tech To Sustainability Agri-Tech Trends Shaping A Greener Future",
    link: November,
    image: Novemberimg,
    type: "Magazine",
    published: "Tech Vist IT Pvt Ltd",
    month: "November",
    mode: "Print & Online",
  },
  {
    title: "Empowering The Next Generation: A New Era in Agriculture",
    link: octoberMagzine,
    image: octoberImage,
    type: "Magazine",
    published: "Tech Vist IT Pvt Ltd",
    month: "October",
    mode: "Print & Online",
  },
  {
    title: "Empowering Agriculture Ganesha's Blueprint for a Sustainable Future",
    link: septMagzine,
    image: SeptemberImage,
    type: "Magazine",
    published: "Tech Vist IT Pvt Ltd",
    month: "September",
    mode: "Print & Online",
  },
  {
    title: "A Way To A Sustainable Future",
    link: augustMagzine,
    image: AugustImage,
    type: "Magazine",
    published: "Tech Vist IT Pvt Ltd",
    month: "August",
    mode: "Print & Online",
  },
  {
    title: "Ecrop Your Personal Farming Companion",
    link: julyMagzine,
    image: JulyImage,
    type: "Magazine",
    published: "Tech Vist IT Pvt Ltd",
    month: "July",
    mode: "Print & Online",
  },
  {
    title: "Revolutionizing Agriculture The Future Of Farming",
    link: june,
    image: juneImage,
    type: "Magazine",
    published: "Tech Vist IT Pvt Ltd",
    month: "june",
    mode: "Print & Online",
  },

];

const Magazinenew = ({ viewMore }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = viewMore ? 4 : 12;

  // Logic for displaying current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = allPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Total number of pages
  const totalPages = Math.ceil(allPosts.length / postsPerPage);

  // Handle page change for pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  return (
    <div className="e-magzine">
      <Title title="e-CROPTODAY Magazine" />
      <div className="row">
        {currentPosts.map((blog, index) => (
          <div className="col-lg-6 col-md-12 mb-4" key={index}>
            <div className="card border-0 shadow">
              <div className="row g-0">
                <div className="col-lg-4 col-md-4">
                  <Link to={blog.link} target="_blank">
                    <img src={blog.image} className="img-fluid" alt={blog.title} />
                  </Link>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="card-body">
                    <h4 className="card-title">
                      <Link to={blog.link} target="_blank">
                        {blog.title}
                      </Link>
                    </h4>
                    <p className="card-text"><span>Product Type:</span> {blog.type}</p>
                    <p className="card-text"><span>Published By:</span> {blog.published}</p>
                    <p className="card-text"><span>Publication Month:</span> {blog.month}</p>
                    <p className="card-text"><span>Mode:</span> {blog.mode}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {viewMore ? (
        <Link to="/e-magazine" target="_blank" className="text-center">
          <button type="submit" className="button">View More</button>
        </Link>
      ) : (
        <div className="pagination-wrapper">
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                  Previous
                </button>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li key={index + 1} className="page-item">
                  <button
                    className={`page-link ${currentPage === index + 1 ? 'active' : ''}`}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button className="page-link" onClick={nextPage} disabled={currentPage === totalPages}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Magazinenew;
