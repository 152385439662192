import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Blog.css";
import thinkGreenImage from "../../asset/think-greenn.webp";
import sustainableAgImage from "../../asset/understanding-sustainable-agriculture.webp";
import greenLogisticsImage from "../../asset/green-logistics.webp";
import climateSkillsImage from "../../asset/CLIMATE-SKILLS.webp";
import marineTechImage from "../../asset/innovative-marine-cloud.jpg";
import sustainabilityImage from "../../asset/Sustainability_Sector1.jpg";
import climateChangeImage from "../../asset/climate-change1.webp";
import carbonFootprintImage from "../../asset/carbon-footprint.jpg";
import richSoilsImage from "../../asset/type-soil.jpg";
import rainfallSoilImage from "../../asset/17-MAY.jpg";
import soilErosionImage from "../../asset/16-may2.jpg";
import parisAgreementImage from "../../asset/fcfc.webp";
import droneSoilImage from "../../asset/Drone-soil.webp";
import satelliteRemoteImage from "../../asset/satellite-remote1.webp";
import ecoFriendlyImage from "../../asset/06-May-Blog.webp";
import tobaccoPricesImage from "../../asset/03-May-Blog.webp";
import researchImage from "../../asset/Key2.webp";
import esgImage from "../../asset/esg-1.webp";
import organicFarmingImage from "../../asset/organic-farm.webp";
import foodSecurityImage from "../../asset/food.webp";
import wastewaterImage from "../../asset/water-waste.webp";
import earthDayImage from "../../asset/EARTH-DAY.webp";
import plantDiseaseImage from "../../asset/blog-20.webp";
import plantDiseases2Image from "../../asset/plant-2.jpeg";
import commonDiseasesImage from "../../asset/BLOG.webp";
import farming5GImage from "../../asset/5G-farming.jpeg";
import greenGdpImage from "../../asset/gdp.webp";
import automatedMonitoringImage from "../../asset/01-Apr-Blog.webp";
import HighYielding from '../../asset/High-Yielding Crop Varieties Key to Agricultural Sustainability and Food Security.webp'
import GreenTechnologies from '../../asset/Green Technologies Way For A Sustainable Future.webp'
import FutureofCloud from '../../asset/The Future of Cloud Seeding in Agriculture for Drought Relief.webp'
import HostaSustainable from '../../asset/How-to-host-a-sustainable-event-tips-for-eco-friendly-gatherings.png'
// Sample data
const allPosts = [
  {
    title: "How to Host a Sustainable Event: Eco-Friendly Tips",
    link: "https://precisiongrowindia.blogspot.com/2024/11/how-to-host-sustainable-event-eco-friendly-tips.html",
    image: HostaSustainable,
    excerpt: "Are you planning to organize an event this year...",
    author: "AMARBEN PATNI",
    date: "November 22, 2024",
  },
  {
    title: "The Future of Cloud Seeding in Agriculture for Drought Relief",
    link: "https://precisiongrowindia.blogspot.com/2024/10/future-cloud-seeding-agriculture-drought-solution.html",
    image: FutureofCloud,
    excerpt: "As we navigate through the escalating impacts of climate change, agriculture faces unprecedented challenges...",
    author: "AMARBEN PATNI",
    date: "October 25, 2024",
  },
  {
    title: "Green Technologies: Way For A Sustainable Future",
    link: "https://precisiongrowindia.blogspot.com/2024/10/green-technologies-sustainable-future-environment.html",
    image: GreenTechnologies,
    excerpt: "In recent years, the word ‘sustainable’ has become a buzzword all over the globe...",
    author: "AMARBEN PATNI",
    date: "October 9, 2024",
  },
  {
    title: "High-Yielding Crop Varieties: Key to Agricultural Sustainability and Food Security",
    link: "https://precisiongrowindia.blogspot.com/2024/09/high-yielding-crop-varieties-food-security-sustainability.html",
    image: HighYielding,
    excerpt: "According to WHO, India’s Global Hunger Index number is 111 out of 125 countries...",
    author: "AMARBEN PATNI",
    date: "September 30, 2024",
  },
  {
    title: "Think Green, Live Green For A Sustainable Future",
    link: "https://precisiongrowindia.blogspot.com/think-green-live-green-sustainable-future",
    image: thinkGreenImage,
    excerpt: "Living green isn’t just a trend—it’s a mindset, a way of life that allows us to take responsibility for our impact on the planet....",
    author: "AMARBEN PATNI",
    date: "September 28, 2024",
  },
  {
    title: "Sustainable Agriculture: The Key to a Greener Future and Food Security",
    link: "https://precisiongrowindia.blogspot.com/blog-post_14",
    image: sustainableAgImage,
    excerpt: "The world’s population is now above 8 billion and the resources are falling...",
    author: "AMARBEN PATNI",
    date: "August 14, 2024",
  },
  {
    title: "7 Innovative Green Logistics Trends Revolutionizing Sustainable Supply Chains in 2024",
    link: "https://precisiongrowindia.blogspot.com/green-logistics-sustainable-solutions",
    image: greenLogisticsImage,
    excerpt: "Climate change is one of the leading crises in the world and it has been a priority....",
    author: "AMARBEN PATNI",
    date: "July 30, 2024",
  },
  {
    title: "Climate Skills: A Sustainable Career Path",
    link: "https://precisiongrowindia.blogspot.com/climate-skills-sustainable-career-path",
    image: climateSkillsImage,
    excerpt: "Climate change is a long-term change in the average weather patterns...",
    author: "AMARBEN PATNI",
    date: "July 19, 2024",
  },
  {
    title: "Innovative Marine Cloud Brightening Technology Tested to Combat Global Warming",
    link: "https://precisiongrowindia.blogspot.com/marine-cloud-brightening-technology-combat-global-warming",
    image: marineTechImage,
    excerpt: "In the face of increasing global temperatures and unprecedented climate change...",
    author: "AMARBEN PATNI",
    date: "June 21, 2024",
  }, {
    "title": "Boosting Job Creation Through Sustainability: Renewable Energy, EVs, and Green Practices",
    "link": "https://precisiongrowindia.blogspot.com/blog-post_18",
    "image": sustainabilityImage ,
    "excerpt": "In recent years, the term “sustainability” has surged in popularity, driven...",
    "date": "June 18, 2024"
  },
  
  {
    "title": "Climate Change Threatens Vital Soil Biodiversity | Impact on Soil-Dwelling Species",
    "link": "https://precisiongrowindia.blogspot.com/2024/06/impact-of-climate-change-on-soil-dwelling-species.html",
    "image": climateChangeImage ,
    "excerpt": "Soil-dwelling creatures are crucial for Earth's carbon cycle. Thousands of species, like mites and springtails...",
    "author": "AMARBEN PATNI",
    "date": "June 05, 2024"
  },
  

  {
      "title": "Understanding Carbon Footprint: Impact, Importance, and How to Reduce It",
      "link": "https://precisiongrowindia.blogspot.com/understanding-carbon-footprint-impact-reduce",
      "image": carbonFootprintImage ,
      "excerpt": "Every time you charge your phone, start your bike, use a paper to write, or various...",
      "author": "AMARBEN PATNI",
      "date": "May 25, 2024"
    },
    {
      "title": "Discover the Rich Tapestry of Soils in India: A Journey Through Nature's Palette",
      "link": "https://precisiongrowindia.blogspot.com/2024/05/rich-types-of-indian-soils.html",
      "image":richSoilsImage ,
      "excerpt": "The vast landscape of India is diverse in its availability of soil all across its length...",
      "author": "AMARBEN PATNI",
      "date": "May 21, 2024"
    },
    {
      "title": "Understanding How Rainfall Affects Soil Erosion: The Physics Behind It",
      "link": "https://precisiongrowindia.blogspot.com/climate-skills-sustainable-career-path",
      "image":rainfallSoilImage ,
      "excerpt": "How rainfall interacts with soil plays a significant role in causing soil erosion, a major issue...",
      "author": "AMARBEN PATNI",
      "date": "May 17, 2024"
    },
    {
      "title": "Why Soil Erosion is a Major Challenge for Sustainable Soil Management",
      "link": "https://precisiongrowindia.blogspot.com/blog-post_16",
      "image": soilErosionImage ,
      "excerpt": "Despite almost a century of research and extension efforts, soil erosion by water...",
      "author": "AMARBEN PATNI",
      "date": "May 16, 2024"
    },
    {
      "title": "Unlocking Synergies: How the Paris Agreement and the 2030 Agenda Work Hand-in-Hand for a Sustainable Future",
      "link": "https://precisiongrowindia.blogspot.com/paris-agreement-2030-agenda-sustainable-development-connections",
      "image": parisAgreementImage ,
      "excerpt": "The 2030 Agenda and the Paris Agreement are both universal visions and are both based on being...",
      "author": "AMARBEN PATNI",
      "date": "May 11, 2024"
    },
    {
      "title": "Drone Soil Analysis: Multispectral Remote Sensing for Soil Mapping",
      "link": "https://precisiongrowindia.blogspot.com/drone-soil-analysis-multispectral",
      "image": droneSoilImage ,
      "excerpt": "Making farmland Sustainable and productive for a long period has become a pressing...",
      "date": "May 9, 2024"
    },
    {
      "title": "Unlocking Insights: How Satellite Remote Sensing Revolutionizes Forest Fire Detection",
      "link": "https://precisiongrowindia.blogspot.com/blog-post_07",
      "image": satelliteRemoteImage ,
      "excerpt": "The word Sustainable is not just an uncommon word anymore but has become an important word of daily use...",
      "author": "AMARBEN PATNI",
      "date": "May 7, 2024"
    },
    {
      "title": "Is 2024 the Year of Sustainable Eco-Friendly Real Estate? Exploring Trends, Innovations, and Green Development",
      "link": "https://precisiongrowindia.blogspot.com/blog-post",
      "image":ecoFriendlyImage ,
      "excerpt": "The word Sustainable is not just an uncommon word anymore but has become an important word of daily use...",
      "author": "AMARBEN PATNI",
      "date": "May 6, 2024"
    },
    {
      "title": "Rising Tobacco Prices in Andhra as Brazil and Indonesia Crop Failures Hit Global Markets: What You Need to Know",
      "link": "https://precisiongrowindia.blogspot.com/tobacco-prices-rise-andhra-crop-failure-brazil-indonesia",
      "image": tobaccoPricesImage ,
      "excerpt": "Tobacco known as the ‘Golden Leaf’ is one of the most profitable commercial cash crops...",
      "author": "AMARBEN PATNI",
      "date": "May 2, 2024"
    },
    {
      "title": "Research and Development: Key to Safeguarding Food Security and Nutrition",
      "link": "https://precisiongrowindia.blogspot.com/research-and-development-key-to",
      "image": researchImage ,
      "excerpt": "India stands at a critical juncture in its food security narrative...",
      "author": "AMARBEN PATNI",
      "date": "May 1, 2024"
    },
    {
      "title": "The Vital Role of ESG in Addressing Climate Change: Understanding its Impact, Importance and Solutions",
      "link": "https://precisiongrowindia.blogspot.com/research-and-development-key-to",
      "image": esgImage ,
      "excerpt": "Environmental, social, and governance (ESG) has become an important measurement to report on for businesses...",
      "author": "AMARBEN PATNI",
      "date": "April 29, 2024"
    },
    {
      "title": "Is a 40 Lakh Yearly Income Possible with Organic Farming? Let’s Find Out Its Potential",
      "link": "https://precisiongrowindia.blogspot.com/blog-post_27",
      "image": organicFarmingImage ,
      "excerpt": "In today’s world if a man starts a business, he expects a certain return to propagate his venture to new heights...",
      "author": "AMARBEN PATNI",
      "date": "April 27, 2024"
    },
    {
      "title": "Ending World Hunger: A Global Challenge for Food Security",
      "link": "https://precisiongrowindia.blogspot.com/blog-post",
      "image":foodSecurityImage ,
      "excerpt": "We have all felt that burning sensation in our stomach when we get hungry. This urgent demand...",
      "author": "AMARBEN PATNI",
      "date": "April 26, 2024"
    },
    {
      "title": "Revolutionizing Agriculture: Using Wastewater to Water Crops Sustainably",
      "link": "https://precisiongrowindia.blogspot.com/wastewater-irrigation",
      "image": wastewaterImage ,
      "excerpt": "In recent years, wastewater irrigation has gained significant attention as a potential solution to water scarcity in agriculture...",
      "author": "AMARBEN PATNI",
      "date": "April 23, 2024"
    },
    {
      "title": "World Earth Day 2024 | Find out how plastics are affecting the environment",
      "link": "https://precisiongrowindia.blogspot.com/plastics-impact-environment",
      "image": earthDayImage ,
      "excerpt": "Earth is a beautiful life Sustaining planet in the solar system, it’s roughly 4.5 billion years old and is still...",
      "author": "AMARBEN PATNI",
      "date": "April 22, 2024"
    },
    {
      "title": "Plant Disease Control Tips: Canker, And Other Fungal Diseases (Final Part)",
      "link": "https://precisiongrowindia.blogspot.com/plant-disease-tips",
      "image": plantDiseaseImage ,
      "excerpt": "Plant blight is a common disease. Remember the potato famine in the 1840s? As a result of the blight, one million...",
      "author": "AMARBEN PATNI",
      "date": "April 20, 2024"
    },
    {
      "title": "Exploring Plant Diseases Identification: A Guide (Part 2)",
      "link": "https://precisiongrowindia.blogspot.com/common-plant-diseases2",
      "image": plantDiseases2Image ,
      "excerpt": "Powdery mildew is a common fungus that affects a wide variety of plants. It is easily identified and appears...",
      "author": "AMARBEN PATNI",
      "date": "April 19, 2024"
    },
    {
      "title": "Exploring Common Plant Diseases: A Guide (Part 1)",
      "link": "https://precisiongrowindia.blogspot.com/common-plant-diseases",
      "image":commonDiseasesImage ,
      "excerpt": "Like us humans, plants also get diseases and as we seek professional help to get better, plants also need proper...",
      "author": "AMARBEN PATNI",
      "date": "April 18, 2024"
    },
    {
      "title": "12 Ways 5G Will Transform Farming and Agriculture",
      "link": "https://precisiongrowindia.blogspot.com/how-5g-will-transform-farming-and",
      "image":farming5GImage ,
      "excerpt": "How can 5G technology be set to shake up the world of farming and agriculture?",
      "author": "AMARBEN PATNI",
      "date": "April 17, 2024"
    },
    {
      "title": "Understanding Green GDP And Its Implications For Sustainable Development",
      "link": "https://precisiongrowindia.blogspot.com/green-gdp-sustain",
      "image":greenGdpImage ,
      "excerpt": "In today's global landscape, where environmental concerns are at the forefront of discussions, the concept of Green GDP...",
      "author": "AMARBEN PATNI",
      "date": "April 16, 2024"
    },
    {
      "title": "Revolutionizing Agriculture With Automated Satellite Monitoring Service",
      "link": "https://precisiongrowindia.blogspot.com/Agriculture-Revolutionizing",
      "image":automatedMonitoringImage ,
      "excerpt": "In the face of global warming, agriculture is confronting unprecedented challenges. With rising temperatures impacting soil health...",
      "author": "AMARBEN PATNI",
      "date": "April 2, 2024"
    },
  // Add other posts similarly
];

const Blog = ({ viewMore, columnClass = 'col-lg-4' }) => { // Default column size
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = viewMore ? 3 : 16;

  // Logic for displaying current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = allPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Total number of pages
  const totalPages = Math.ceil(allPosts.length / postsPerPage);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  return (
    <>
      <div className="row">
        {currentPosts.map((blog, index) => (
          <div className={`${columnClass} mb-4`} key={index}>
            <div className="card border-0 shadow">
              <div className="row g-0">
                <div className="col-lg-5">
                  <Link to={blog.link} target="_blank">
                    <img src={blog.image} className="img-fluid" alt={blog.title} />
                  </Link>
                </div>
                <div className="col-lg-7">
                  <div className="card-body">
                    <h4 className="card-title">
                      <Link to={blog.link} target="_blank">
                        {blog.title}
                      </Link>
                    </h4>
                    <p className="card-text">{blog.excerpt}</p>
                    <div className="ad-info mb-2">
                      <small className="text-body-secondary">
                        <i className="fa-regular fa-user mx-1"></i>
                        <span className="fw-bold">{blog.author}</span>
                        <i className="fa-solid fa-calendar-days mx-2"></i>
                        <span className="fw-bold">{blog.date}</span>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {viewMore ? (
        <Link to="/e-journal" target="_blank" className="text-center">
          <button type="submit" className="button mb-3">View More</button>
        </Link>
      ) : (
        <div className="pagination-wrapper">
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                  Previous
                </button>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li key={index + 1} className="page-item">
                  <button
                    className={`page-link ${currentPage === index + 1 ? 'active' : ''}`}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button className="page-link" onClick={nextPage} disabled={currentPage === totalPages}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default Blog;
