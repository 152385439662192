import React from 'react'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import Titletag from '../Titletag/Titletag'
import Downborder from '../Editorial/Downborder'

const Publication = () => {
  return (
    <div>
       <div className="container">
        <Breadcrumbs navhome="Home" navnext="Publication Ethics" />
        <Titletag icon="fa-solid fa-users-between-lines" title="PUBLICATION ETHICS" />
        <Downborder />
        <div className="guidediv">
          <p className='mb-5'><b>e-CROPTODAY</b> adheres to the highest standard of academic publication ethics. The peer-review process is an extremely important part of the publication process; it improves the quality of the published article. All concerned parties like the author, editorial board members, and publisher ensure the implementation of ethical behavior to publish a research paper</p>
          <ul>
            <li>
              <h5>PUBLICATION DECISIONS:</h5>
              <p>The members of the Editorial Board work unbiasedly and impartially, and their decision is final regarding the publication of the paper concerned. The selection process involves thorough validation of the work. According to the journal's policies, all editorial board members are informed about legal requirements, copyright infringement, peer review, and plagiarism policies.</p>
            </li>
            <li>
              <h5>INTEGRITY</h5>
              <p>Editors of the Journal ensure there is no consideration of the author’s gender, religion, sex, origin, religion, political philosophy, and citizenship in evaluating the manuscript.

              </p>
            </li>
            <li>
              <h5>PRIVACY</h5>
              <p>All publication-related correspondence must be sent to the corresponding author by authorized email ID only. Information collected must be treated as confidential and strict privacy policy must be adhered to.

              </p>
            </li>
            <li>
              <h5>DISCLOSURE AND CONFLICTS OF INTEREST</h5>
              <p>If any research paper is unpublished, our editorial board members are not allowed to use the material. The reviewers ensure that there are no conflicts of interest to accept the paper against its selection procedure.
              </p>
            </li>
            <li>
              <h5>CONFIDENTIALITY</h5>
              <p>Manuscript received from the author is treated as a highly confidential document and we never disclose its details to anyone other than the editor and authorized person by the editor.
              </p>
            </li>
            <li>
              <h5>STANDARDS OF OBJECTIVITY</h5>
              <p>Reviews are conducted in a way that is not influenced by personal feelings or opinions, i.e., the review is objectively carried out. Personal comments or criticism about the author are inappropriate. The views of the Referees should be clearly expressed with sufficient supporting arguments.
              </p>
            </li>
            <li>
              <h5>RESPONSIBILITIES OF AUTHORS
              </h5>
              <p>The reports given by the Authors of the original research are expected to be that of an exact work performed with its objective and discussion. The paper must contain proper details and references so that others can get sufficient details and permit others to avoid replicating the work.
              </p>
            </li>
            <li>
              <h5>ORIGINALITY AND PLAGIARISM</h5>
              <p>As authors, it is our responsibility to uphold the integrity of our work. When writing articles, it is essential to ensure that the content is entirely original and articulated in our own words. This not only reflects our commitment to research excellence but also respects the intellectual property of others. Submitting the same article to multiple publications undermines the credibility of the research and compromises its value. Every piece of work should stand on its own, offering unique insights and findings.  
              </p>
              <p>When building on the work of others, proper citation or quotation is imperative. Acknowledging sources is not merely a formality—it is a vital element of academic honesty and integrity. In alignment with the latest UGC (University Grants Commission) guidelines, authors must ensure that their submissions are free from plagiarism. Plagiarism, whether intentional, reckless, or unintentional, is considered intellectual theft. To foster high-quality scholarly publications, <b>e-CROPTODAY</b> has implemented clear policies to promote awareness about the importance of originality, particularly in the fields of agri-tech and sustainability.
              </p>
            </li>
            <li>
              <h5>OPEN ACCESS POLICY</h5>
              <p className='mb-4'><b>e-CROPTODAY</b>, an open-access journal that provides immediate worldwide, barrier-free access to the full text of research articles, the content of which is freely available without charge or subscription to the journal. Users are allowed to read, download, copy, distribute, print, search, or link to the full texts of the articles or use them for any other lawful purpose, without asking prior permission from the publisher or the author. This is under the Budapest Open Access Initiative (BOAI is a public statement of principles relating to open access to the research literature, which was released to the public on February 14, 2002) definition of open access.</p>
            <hr/>
            <p className='mt-4'>The author represents and warrants that the submitted paper is their original work and has not been previously published or submitted for publication in any form. If the work is a revision, the author confirms that (1) it has been substantially revised and updated from the original version, or (2) they have obtained the necessary rights to republish the work in <b>e-CROPTODAY</b>. The author further affirms that they hold the right to grant copyright to this journal. To the best of their knowledge, the paper is neither defamatory towards any person or product nor does it infringe upon the copyrights of any third party. If the paper contains copyrighted material not owned by the author, the author confirms that such material is properly identified, acknowledged, and used with the copyright holder’s permission.
            </p>
            <p>In the event of any future dispute regarding the copyrighted material included in the paper, the author agrees to indemnify and hold harmless <b>e-CROPTODAY</b> and its team from any claims or liabilities.</p>
            </li>
          </ul>

          <div className="col-md-6 publication-cont">
           <h4>Contact Publisher and Editorial Office </h4>
           <h5>Amarben Patni</h5>
           <h6>(Chief Editor, e-CROPTODAY  Director )</h6>
           <h5>Precision Grow <br/>(A Unit Of Tech VIsit It Pvt Ltd.)</h5> 
             <p>Mahape, Navi Mumbai-400710.</p>
             
             <div className="">
                 <ul style={{listStyle:'none'}}>
                   <li><span>Phone:</span> 8097626333</li>
                   <li><span>Mobile:</span> +918097283444</li>
                   <li><span>Email</span> : chiefeditor@ecroptoday.co.in</li>
                   <li><span>Website</span> : www.ecroptoday.co.in </li>
                 </ul>
               </div>
        
             </div>
        </div>

        
        
    </div>
    </div>
  )
}

export default Publication
