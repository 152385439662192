import React from 'react'
import './Downborder.css'
import Border from '../../asset/Border.png';

const Downborder = () => {
  return (
    <div>
        <div class="downborder">
            <img src={Border} alt="downborder"/>
        </div>
    </div>
  )
}

export default Downborder