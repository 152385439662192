import React from 'react';
import Magazinenew from '../../Home/Magazinenew';

const E_magazine = () => {

  return (
    <>
        <div className="container">
        <Magazinenew viewMore={false} />
    </div>
    </>

  );
};

export default E_magazine;
