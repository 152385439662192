// E_journal.js
import React from 'react';
import Blog from '../../Home/Blog'; // Adjust the import path as necessary
import Title from '../../Titleholder/Title';

const E_journal = () => {
  return (
    <div className="container">
       <Title title="Featured Blogs" />
      <div className='row justify-content-center align-items-center'>
        <div className='col-lg-12'>
        <Blog columnClass="col-lg-6" viewMore={false} /> {/* 2 columns layout */}
        </div>
      </div>
     
    </div>
  );
};

export default E_journal;
