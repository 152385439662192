// archive.js



const API_archive = [
  {
    month: "June 2024",
    volume: "Volume-1 Issue-1, June 2024",
    articles: [
      {
        s_no: 1,
        authors: "Amarben Patni",
        title: "Revolutionizing Agriculture with Automated Satellite Monitoring Service.",
        page_no: "6-7",
        pdf_url: "/assets/june/Revolutionizing_Agriculture.pdf"
      },
      {
        s_no: 2,
        authors: "Kirti Rawal",
        title: "Is 2024 the Year of Sustainable Eco-Friendly Real Estate?",
        page_no: "9-11",
        pdf_url: "/assets/june/Sustainable_EcoFriendly.pdf"
      },
      {
        s_no: 3,
        authors: "Swpanil Tiwari",
        title: "Technology Transfer of eCrop Module to PRECISION GROW on World Environment Day",
        page_no: "12-13",
        pdf_url: "/assets/june/World_EnvironmentDay.pdf"
      },
      {
        s_no: 4,
        authors: "Krishna Shambhu Kanu",
        title: "Drone Soil Analysis: Multispectral Remote Sensing for Soil Mapping",
        page_no: "14-16",
        pdf_url: "/assets/june/Soil_Mapping.pdf"
      },
      {
        s_no: 5,
        authors: "Rajni Dhankanthi",
        title: "Exploring connections between the Paris Agreement and the 2030 Agenda for Sustainable Development",
        page_no: "18-19",
        pdf_url: "/assets/june/Sustainable_Development.pdf"
      },
      {
        s_no: 6,
        authors: "Dipti Chavan",
        title: "End All Hunger: The challenge to tackle the global food security",
        page_no: "21-22",
        pdf_url: "/assets/june/hunger_challenge.pdf"
      },
      {
        s_no: 7,
        authors: "Vandana Prajapati",
        title: "Tobacco prices to rise in Andhra after crop failure in Brazil & Indonesia",
        page_no: "24-25",
        pdf_url: "/assets/june/tobacco_prices.pdf"
      },
      {
        s_no: 8,
        authors: "Pooja Bhise",
        title: "The Vital Role of ESG in Addressing Climate Change: Understanding its Impact, Importance and Solutions",
        page_no: "27-28",
        pdf_url: "/assets/june/climate_changes.pdf"
      },
      {
        s_no: 9,
        authors: "Swpanil Tiwari",
        title: "The Application of Machine Learning in Crop Mapping",
        page_no: "29-32",
        pdf_url: "/assets/june/Machine_Learning.pdf"
      },
      {
        s_no: 10,
        authors: "Snehal Nagwekar",
        title: "Understanding the Challenges of Global Food Security: The Pivotal Role of Fertilizers in Addressing this Issue.",
        page_no: "34-35",
        pdf_url: "/assets/june/Food_Security.pdf"
      },
      {
        s_no: 11,
        authors: "Neeraj Dakua",
        title: "Understanding Green GDP and Its Implications for Sustainable Development",
        page_no: "37-38",
        pdf_url: "/assets/june/Green_GDP.pdf"
      },
      // More articles
    ]
  },
  {
    month: "July 2024",
    volume: "Volume-1 Issue-2, July 2024",
    articles: [
      {
        s_no: 1,
        authors: "Amarben Patni",
        title: "United Nation Warns that Earth will Soon hit record high temperatures",
        page_no: "5-7",
        pdf_url: "/assets/july/jul_artical_1.pdf"
      },
      {
        s_no: 2,
        authors: "Swpanil Tiwari",
        title: "Spreading Rock Dust on Fields: A Revolutionary Solution for Carbon Sequestration",
        page_no: "8-10",
        pdf_url: "/assets/july/jul_artical_2.pdf"
      },
      {
        s_no: 3,
        authors: "Rajni Dhankanthi",
        title: "Exploring the Significance of Millets in Global Nutrition",
        page_no: "12-15",
        pdf_url: "/assets/july/jul_artical_3.pdf"
      },
      {
        s_no: 4,
        authors: "Prachi Athavale",
        title: "Climate Change is Depleting Life in Soil",
        page_no: "16-18",
        pdf_url: "/assets/july/jul_artical_4.pdf"
      },
      {
        s_no: 5,
        authors: "Kirti Rawal",
        title: "Carbon Footprint: How Our Actions Today Impact the Planet Tomorrow",
        page_no: "20-21",
        pdf_url: "/assets/july/jul_artical_5.pdf"
      },
      {
        s_no: 6,
        authors: "Krishna Kanu",
        title: "How Forests Store Carbon",
        page_no: "22-24",
        pdf_url: "/assets/july/jul_artical_6.pdf"
      },
      {
        s_no: 7,
        authors: "Dipti Chavan",
        title: "The Rich Tapestry of Soils in India",
        page_no: "26-28",
        pdf_url: "/assets/july/jul_artical_7.pdf"
      },
      {
        s_no: 8,
        authors: "Vandana Prajapati",
        title: "The Physics of Rainfall Effects on Soil Erosion",
        page_no: "30-32",
        pdf_url: "/assets/july/jul_artical_8.pdf"
      },
      {
        s_no: 9,
        authors: "Pooja Bhise",
        title: "Unlocking Insights: How Satellite Remote Sensing Revolutionizes Forest Fire Detection",
        page_no: "34-35",
        pdf_url: "/assets/july/jul_artical_9.pdf"
      },
      {
        s_no: 10,
        authors: "Neeraj Dakua",
        title: "How 5G Will Transform Farming and Agriculture?",
        page_no: "36-37",
        pdf_url: "/assets/july/jul_artical_10.pdf"
      },
      {
        s_no: 11,
        authors: "Swapnil Tiwari",
        title: "The Indian Council of Agricultural Research (ICAR) : A Pillar of India's Agricultural Progress",
        page_no: "40-42",
        pdf_url: "/assets/july/jul_artical_11.pdf"
      },
      {
        s_no: 12,
        authors: "Snehal Nagwekar",
        title: "The Difference Between organic Farming and Regenerative Farming",
        page_no: "43-45",
        pdf_url: "/assets/july/jul_artical_12.pdf"
      },
      // More articles
    ]
  },
  {
    month: "August 2024",
    volume: "Volume-1 Issue-3, August 2024",
    articles: [
      {
        s_no: 1,
        authors: "Swpanil Tiwari",
        title: "We Should Be Taught About Sustainable Practices in School",
        page_no: "5-7",
        pdf_url: "/assets/august/Aug_artical_1.pdf"
      },
      {
        s_no: 2,
        authors: "Narendra Nor",
        title: "Sustainability Sector to Boost Job Creation",
        page_no: "13-15",
        pdf_url: "/assets/august/Aug_artical_2.pdf"
      },
      {
        s_no: 3,
        authors: "Rajni Dhankanthi",
        title: "India Encounters Substantial Challenges in the Agriculture Sector",
        page_no: "16-18",
        pdf_url: "/assets/august/Aug_artical_3.pdf"
      },
      {
        s_no: 4,
        authors: "Kirti Rawal",
        title: "Understanding the Components of Soil: An Overview of Soil Profile",
        page_no: "20-24",
        pdf_url: "/assets/august/Aug_artical_4.pdf"
      },
      {
        s_no: 5,
        authors: "Krishna Kanu",
        title: "Understanding Natural Cycles that Impact Soil",
        page_no: "26-27",
        pdf_url: "/assets/august/Aug_artical_5.pdf"
      },
      {
        s_no: 6,
        authors: "Dipti Chavan",
        title: "Carbon Footprint: How Our Actions Today Impact the Planet Tomorrow",
        page_no: "28-31",
        pdf_url: "/assets/august/Aug_artical_6.pdf"
      },
      {
        s_no: 7,
        authors: "Vandana Prajapati",
        title: "The Importance of R&D in Addressing Agricultural",
        page_no: "32-33",
        pdf_url: "/assets/august/Aug_artical_7.pdf"
      },
      {
        s_no: 8,
        authors: "Swpanil Tiwari",
        title: "ICAR CTCRI Celebrates 61st Foundation Day in the Presence of the Hon’ble Governor of Kerala",
        page_no: "35-36",
        pdf_url: "/assets/august/Aug_artical_8.pdf"
      },
      {
        s_no: 9,
        authors: "Pooja Bhise",
        title: "Warming Is Getting Worse. So, They Just Tested a Way to Deflect the Sun",
        page_no: "38-40",
        pdf_url: "/assets/august/Aug_artical_9.pdf"
      },
      {
        s_no: 10,
        authors: "Swpanil Tiwari",
        title: "Precision Grow: Transforming Crop Health Monitoring with the e-CROP Device at ICAR’s 96th Foundation Day",
        page_no: "",
        pdf_url: "/assets/august/Aug_artical_10.pdf"
      },
      {
        s_no: 11,
        authors: "Snehal Nagwekar",
        title: "Save our Soil: Innovative Approaches to Combat Soil Erosion",
        page_no: "45-46",
        pdf_url: "/assets/august/Aug_artical_11.pdf"
      },
      {
        s_no: 12,
        authors: "Vivek Yadav",
        title: "Farmers Benefiting from High-Yielding Varieties of Chinese Potato, Elephant Foot Yam, and Cassava",
        page_no: "47-49",
        pdf_url: "/assets/august/Aug_artical_12.pdf"
      },
      // More articles
    ]
  },


  {
    month: "September 2024",
    volume: "Volume-1 Issue-5, September 2024",
    articles: [
      {
        s_no: 1,
        authors: "Swpanil Tiwari",
        title: "Green Logistics: A Sustainable Path Forward",
        page_no: "6-8",
        pdf_url: "/assets/september/Greenlogistics.pdf"
      },
      {
        s_no: 2,
        authors: "Rita Fredericks",
        title: "Role of Technology in Modern Day Farming",
        page_no: "10-11",
        pdf_url: "/assets/september/Role-of-Technology.pdf"
      },
      {
        s_no: 3,
        authors: "Sweta Jha",
        title: "Farmers to undertake cultivation of Tur and Urad",
        page_no: "13",
        pdf_url: "/assets/september/farmers-to-undertake.pdf"
      },
      {
        s_no: 4,
        authors: "Sweta Jha",
        title: "Mangroves: Nature’s Coastal Defenders And Climate Change Warriors [Ultimate Guide]",
        page_no: "14-17",
        pdf_url: "/assets/september/Mangrovens.pdf"
      },
      {
        s_no: 5,
        authors: "Rita Fredericks",
        title: "Boost to agriculture industry: India to come up with first Agri-export unit at JNPT",
        page_no: "19-20",
        pdf_url: "/assets/september/boost-to-agriculture.pdf"
      },
      {
        s_no: 6,
        authors: "Rajni Dhankanthi",
        title: "The climate is changing, why aren’t we?",
        page_no: "22-23",
        pdf_url: "/assets/september/climate-is-changing.pdf"
      },
      {
        s_no: 7,
        authors: "Krishna Kanu",
        title: "Agricultural Waste Management",
        page_no: "25-27",
        pdf_url: "/assets/september/agricultural-waste-management.pdf"
      },
      {
        s_no: 8,
        authors: "Rohit Sakpal",
        title: "Deforestation: Understanding Its Impact and What We Can Do to Help",
        page_no: "29-31",
        pdf_url: "/assets/september/Deforestation.pdf"
      },
      {
        s_no: 9,
        authors: "Snehal Nagwekar",
        title: "Innovative Farming Techniques for Tuber Crop Cultivation",
        page_no: "33-34",
        pdf_url: "/assets/september/Innovative-Farming.pdf"
      },
      {
        s_no: 10,
        authors: "Krishna Kanu",
        title: "Ducks’ natural alternative to pesticides",
        page_no: "36-37",
        pdf_url: "/assets/september/Ducks.pdf"
      },
      {
        s_no: 11,
        authors: "Swpanil Tiwari",
        title: "Climate Skills: A Sustainable Career Path",
        page_no: "38-39",
        pdf_url: "/assets/september/Climate-Skills.pdf"
      },
      {
        s_no: 12,
        authors: "Rita Fredericks",
        title: "Raising Farmer Income: A Critical Priority for the Indian Economy",
        page_no: "40-41",
        pdf_url: "/assets/september/Raising-Farmer.pdf"
      },
      // More articles
    ]
  },

  {
    month: "October 2024",
    volume: "Volume-1 Issue-4, October 2024",
    articles: [
      {
        s_no: 1,
        authors: "Swpanil Tiwari",
        title: "Achieving Net Zero: The Impact of Sustainability Practices in Organizations",
        page_no: "6-9",
        pdf_url: "/assets/october/Net-Zero.pdf"
      },
      {
        s_no: 2,
        authors: "Rita Fredericks",
        title: "Crop Protection Approaches in the Context of Climate Change",
        page_no: "11-13",
        pdf_url: "/assets/october/Crop-Protection.pdf"
      },
      {
        s_no: 3,
        authors: "Sweta Jha",
        title: "Solid rain: a water revolution for sustainable agriculture",
        page_no: "16-18",
        pdf_url: "/assets/october/solid-rain.pdf"
      },
      {
        s_no: 4,
        authors: "Rajni Dhankanthi",
        title: "Reimagining Governance Towards Viksit Bharat",
        page_no: "20-23",
        pdf_url: "/assets/october/vikasit-bharat.pdf"
      },
      {
        s_no: 5,
        authors: "Krishna Kanu",
        title: "The Fresh Fruits Industry in India: Navigating Scope, Trends, and Technological Dynamics",
        page_no: "24-26",
        pdf_url: "/assets/october/fresh-fruits.pdf"
      },
      {
        s_no: 6,
        authors: "Rohit Sakpal",
        title: "Bayer’s ‘ForwardFarm’ Initiative in India: Pioneering Sustainable Rice Cultivation and Regenerative Agriculture",
        page_no: "28-30",
        pdf_url: "/assets/october/bayer's.pdf"
      },
      {
        s_no: 7,
        authors: "Snehal Nagwekar",
        title: "Ethanol Push Drives Record Maize Acreage in Kharif Season 2024",
        page_no: "32-33",
        pdf_url: "/assets/october/kharif-season.pdf"
      },
      {
        s_no: 8,
        authors: "Swpanil Tiwari",
        title: "Aeroleaf: The First Tree That Generates Infinite Energy For Homes",
        page_no: "35-36",
        pdf_url: "/assets/october/aeroleaf.pdf"
      },
      {
        s_no: 9,
        authors: "Rita Fredericks",
        title: "Carrot Farming Success: Farmer Transforms His Life, Earns Rs 1 Crore Per Year",
        page_no: "38-39",
        pdf_url: "/assets/october/carrot-farming.pdf"
      },
      {
        s_no: 10,
        authors: "Rohit Sakpal",
        title: "The Impact of Big Data on Farm Management: Turning Insights into Action",
        page_no: "41-42",
        pdf_url: "/assets/october/big-data.pdf"
      },
      {
        s_no: 11,
        authors: "Krishna Kanu",
        title: "Sustainability Initiative for Financing Climate Resilience Technologies in Agriculture",
        page_no: "44-45",
        pdf_url: "/assets/october/sustanability-financing.pdf"
      },
    ]
  },

  {
    month: "November 2024",
    volume: "Volume-1 Issue-5, November 2024",
    articles: [
      {
        s_no: 1,
        authors: "Rita Fredericks",
        title: "Crop Insurance And Minimization Of Uncertainty Risk In Agriculture",
        page_no: "06-09",
        pdf_url: "/assets/november/Crop_Insurance_And_Minimization_Of_Uncertainty_Risk_In_Agriculture.pdf"
      },
      {
        s_no: 2,
        authors: "Swpanil Tiwari",
        title: "How ICTs Are Shaping Sustainable And Present Day Farming Systems In India",
        page_no: "11-13",
        pdf_url: "/assets/november/How_ICTs_Are_Shaping_Sustainable_And_Present_Day_Farming_Systems_In_India.pdf"
      },
      {
        s_no: 3,
        authors: "Rohit Sakpal",
        title: "Can We Predict Weather Patterns To Help Farmers Prevent Crop Damage ?",
        page_no: "14-17",
        pdf_url: "/assets/november/Can_We_Predict_Weather_Pattern_To_Help_Farmers_Prevent_Crop_Damage.pdf"
      },
      {
        s_no: 4,
        authors: "Sweta Jha",
        title: "Embracing Sustainability: Our Commitment To a Greener Future",
        page_no: "19-20",
        pdf_url: "/assets/november/Embracing_Sustainability_Our_Commitment_To_a_Greener_Future.pdf"
      },
      {
        s_no: 5,
        authors: "Dr. G. BYJU",
        title: "CTCRI ORGANISED STAKEHOLDERS INTERFACE ON TUBER CROPS AT AMBASAMUDRAM, TIRUNELVELI",
        page_no: "22-25",
        pdf_url: "/assets/november/CTCRI_ORGANISED_STAKEHOLDERS_INTERFACE_ON_TUBER_CROPS_AT_AMBASAMUDR_M_TIRUNELVELI.pdf"
      },
      {
        s_no: 6,
        authors: "Rita Fredericks",
        title: "Exploring The Market For Carbon Credits: Opportunities And Challenges",
        page_no: "27-28",
        pdf_url: "/assets/november/Exploring_The_Market_For_Carbon_Credits_Opportunities_And_Challenges.pdf"
      },
      {
        s_no: 7,
        authors: "Rohit Sakpal",
        title: "Assessing The Impact Of Monsoon On Kharif Crop Damage This Season",
        page_no: "30-31",
        pdf_url: "/assets/november/Assessing_The_Impact_Of_Monsoon_On_Kharif_Crop_Damage_This_Season.pdf"
      },
      {
        s_no: 8,
        authors: "Sweta Jha",
        title: "How Technology is Transforming The Agricultural Sector",
        page_no: "34-35",
        pdf_url: "/assets/november/How_Technology_is_Transforming_The_Agricultural_Sector.pdf"
      },
      {
        s_no: 9,
        authors: "Rohit Sakpal",
        title: "Meet a Farmer Who Became a Millionaire by Cultivating Rambutan and Dragon Fruit",
        page_no: "37-38",
        pdf_url: "/assets/november/Meet_a_Farmer_Who_Became_a_Millionaire_by_Cultivating_Rambutan_and_Dragon_Fruit.pdf"
      },
      {
        s_no: 10,
        authors: "Rita Fredericks",
        title: "Tech Visit IT Pvt. Ltd. Earns SGS ISO/IEC 27001:2022 Certification: A Bold Commitment to Data Security and Pioneering Innovation in Agriculture",
        page_no: "40-42",
        pdf_url: "/assets/november/Tech_Visit_IT_Pvt_Ltd_Earns_SGS_IS_IEC_27001_2022_Certification_A_Bold_Commitment_to_Data_Security_and_Pioneering_Innovation_in_Agriculture.pdf"
      },
      
      // More articles
    ]
  },



  // More months
];

export default API_archive;
