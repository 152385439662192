import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Topnavbar from "./Topnavbar";
import './Subnavbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../asset/vlogo.jpg'; // Use import for images

const Subnavbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const eContentDropdownRef = useRef(null);
  const guidelineDropdownRef = useRef(null); 
  const location = useLocation();

  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);

  const handleDropdownToggle = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (
      (eContentDropdownRef.current && !eContentDropdownRef.current.contains(event.target)) &&
      (guidelineDropdownRef.current && !guidelineDropdownRef.current.contains(event.target))
    ) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isActive = (path) => (location.pathname === path ? 'active' : '');

  return (
    <>
      <Topnavbar />
      <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top">
        <div className="container-fluid">
          <div className="logoimgMob">
            <Link className="navbar-brand w-100" to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <button
            className="navbar-toggler shadow-none"
            type="button"
            aria-controls="navbarNav"
            aria-expanded={isNavbarOpen}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <i className={isNavbarOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"} aria-hidden="true"></i>
          </button>
          <div className={`navbar-collapse ${isNavbarOpen ? "show" : ""}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/')}`} to="/" onClick={() => setIsNavbarOpen(false)}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/about')}`} to="/about" onClick={() => setIsNavbarOpen(false)}>About Us</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/archive')}`} to="/archive" onClick={() => setIsNavbarOpen(false)}>Archives</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="https://ecrop.co.in/" target="_blank" rel="noopener noreferrer" onClick={() => setIsNavbarOpen(false)}>e-CROP</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/editorial')}`} to="/editorial" onClick={() => setIsNavbarOpen(false)}>Editorial Board</Link>
              </li>
              <li className="nav-item logoimg">
                <Link className="navbar-brand" to="https://ecroptoday.co.in/" rel="noopener noreferrer" onClick={() => setIsNavbarOpen(false)}>
                  <img src={logo} alt="Logo" />
                </Link>
              </li>
              
              {/* e-Content Dropdown */}
              <li className="nav-item dropdown" ref={eContentDropdownRef}>
                <Link className="nav-link dropdown-toggle" id="eContentDropdown" role="button"
                  aria-expanded={openDropdown === 'eContent'} onClick={() => handleDropdownToggle('eContent')}>
                  e-Content
                </Link>
                <ul className={`shadow dropdown-menu ${openDropdown === 'eContent' ? 'show' : ''}`} aria-labelledby="eContentDropdown">
                  <li>
                    <Link className="dropdown-item" to="/e-journal" onClick={() => setIsNavbarOpen(false)}>e-Journal</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/e-magazine" onClick={() => setIsNavbarOpen(false)}>e-Magazine</Link>
                  </li>
                </ul>
              </li>
              
              {/* Ethical Publishing Guide Dropdown */}
              <li className="nav-item dropdown" ref={guidelineDropdownRef}>
                <Link className="nav-link dropdown-toggle" id="guidelineDropdown" role="button"
                  aria-expanded={openDropdown === 'guideline'} onClick={() => handleDropdownToggle('guideline')}>
                  Ethical Publishing Guide
                </Link>
                <ul className={`shadow dropdown-menu ${openDropdown === 'guideline' ? 'show' : ''}`} aria-labelledby="guidelineDropdown">
                  <li>
                    <Link className="dropdown-item" to="/publication" onClick={() => setIsNavbarOpen(false)}>Publication Ethics</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/guideline" onClick={() => setIsNavbarOpen(false)}>Guidelines for Author</Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link className={`nav-link ${isActive('/submission')}`} to="/submission" onClick={() => setIsNavbarOpen(false)}>Article Submission</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/contact')}`} to="/contact" onClick={() => setIsNavbarOpen(false)}>Contact</Link>
              </li>
              {/* <li className="nav-item">
                <Link className={`nav-link ${isActive('/contact1')}`} to="/contact1" onClick={() => setIsNavbarOpen(false)}>Contact1</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Subnavbar;
