import React, { useEffect, useState } from "react";
import "./Contact.css";
import axios from "axios";
import Modal from "react-modal";
import AOS from "aos";  // Ensure to import AOS

Modal.setAppElement("#root"); // Required for screen readers to work correctly

const Contact = () => {
  const [otpDisplay, setOtpDisplay] = useState("hidden");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [otpValue, setOtpValue] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    domain: "ecroptoday", // Add the default value for domain here
  });
  const [status, setStatus] = useState({ success: false, error: null });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const script = document.getElementById("otpless-sdk");
    if (!script) {
      const newScript = document.createElement("script");
      newScript.id = "otpless-sdk";
      newScript.src = "https://cdn.otpless.com/otpless.js"; // Ensure this is the correct URL for OTPless
      newScript.async = true;
      document.body.appendChild(newScript);
    }

    const callback = (userinfo) => {
      const emailMap = userinfo.identities.find(
        (item) => item.identityType === "EMAIL"
      );

      const mobileMap = userinfo.identities.find(
        (item) => item.identityType === "MOBILE"
      )?.identityValue;

      const token = userinfo.token;

      const email = emailMap?.identityValue;
      const mobile = mobileMap?.identityValue;
      const name = emailMap?.name || mobileMap?.name;

      console.log("User Info:", { email, mobile, name, token });
    };

    if (window.OTPlessSignin) {
      window.OTPlessSignin = new window.OTPless(callback);
    }

    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  const phoneAuth = () => {
    const phone = document.getElementById("phone").value;
    if (!phone) {
      setOtpDisplay("hidden");
      alert("Please enter your number");
      return;
    }

    console.log("Initiating OTP for phone:", phone); // Debugging line

    if (window.OTPlessSignin) {
      setOtpDisplay("visible");
      setIsOtpRequested(true);
      window.OTPlessSignin.initiate({
        channel: "PHONE",
        phone: phone,
        countryCode: "+91",
      });
    } else {
      console.error("OTPlessSignin is not loaded yet.");
      alert("OTP service not available. Please try again later.");
    }
  };

  const verifyOTP = () => {
    const phone = document.getElementById("phone").value;
    const otp = document.getElementById("otp-input").value;

    console.log("Verifying OTP:", otp); // Debugging line

    if (window.OTPlessSignin) {
      window.OTPlessSignin.verify({
        channel: "PHONE",
        phone: phone,
        otp: otp,
        countryCode: "+91",
      })
        .then((response) => {
          console.log("OTP verification response:", response); // Debugging line
          if (response.success) {
            setIsOtpVerified(true);
            console.log("OTP Verified Successfully");
          } else {
            console.error("OTP Verification Failed");
            alert("Incorrect OTP");
            setIsOtpVerified(false);
          }
        })
        .catch((error) => {
          console.error("Error verifying OTP:", error);
          setIsOtpVerified(false);
        });
    } else {
      console.error("OTPlessSignin is not loaded yet.");
      alert("OTP service not available. Please try again later.");
    }
  };

  const handleOtpChange = (e) => {
    setOtpValue(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ success: false, error: null });

    if (!isOtpVerified) {
      alert("Please verify the OTP before submitting the form.");
      return;
    }

    try {
      const response = await axios.post(
        "https://ecbsf-backend.azurewebsites.net/api/contact/send",
        { ...formData, domain: formData.domain }
      );

      if (response.status === 200) {
        setStatus({ success: true, error: null });
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          domain: "ecroptoday",
        });
        setOtpValue("");
        setIsOtpVerified(false);
        setIsOtpRequested(false);
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
      setStatus({
        error: "Something went wrong. Please try again later.",
        success: false,
      });
    }
  };

  return (
    <>
      <section>
        <div className="bg-banner-c">
          <img src="" alt="Contact Banner" loading="lazy" />
        </div>
      </section>
      <main>
        <section id="contact" className="contact section-bg custom-bg">
          <div className="container" data-aos="fade-up">
            <div className="text-center">
              <h6>SAY HELLO TO US</h6>
              <h1>Reach out and connect</h1>
              <p>
                Reach out and connect with us! We're eager to hear from you.
                Whether you have a burning question, a brilliant idea, or simply
                want to say hello, we're here to listen. Get in touch with our
                friendly team through email, phone, or by filling out our online
                contact form. You can also connect with us on social media for
                the latest updates and engaging discussions. We value your
                feedback and look forward to building a lasting relationship
                with you.
              </p>
            </div>

            <div className="col-lg-10 mx-auto">
              <form onSubmit={handleSubmit} role="form" className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label htmlFor="name">Name *</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Enter your name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <label htmlFor="email">Email Address *</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Enter your Email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="phone">Phone *</label>
                  <input
                    type="tel"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Enter your phone"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <button className="requestOtp" type="button" onClick={phoneAuth}>
                    Request OTP
                  </button>
                  {isOtpRequested && (
                    <div id="otp-section" className={`otp-${otpDisplay}`}>
                      <input
                        className="enterOtp"
                        id="otp-input"
                        placeholder="Enter OTP"
                        value={otpValue}
                        onChange={handleOtpChange}
                      />
                      <button className="ms-2" type="button" onClick={verifyOTP}>
                        Verify OTP
                      </button>
                    </div>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="message">Your Message *</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Your additional messages..."
                    required
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="my-3">
                  {status.error && <div className="error-message">{status.error}</div>}
                  {status.success && <div className="sent-message">Your message has been sent. Thank you!</div>}
                </div>
                <div className="text-center">
                  {isOtpVerified ? (
                    <button type="submit" disabled={status.loading}>
                      Send Message
                    </button>
                  ) : (
                    <p>Please verify OTP to submit the form.</p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>

      {/* Modal */}
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <h2>Message Sent Successfully!</h2>
        <button onClick={() => setModalIsOpen(false)}>Close</button>
      </Modal>
    </>
  );
};

export default Contact;
