import React from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Magazine.css";
import Blog from "./Blog";
// import Title from "../Titleholder/Title";
import '../Sidebar/Sidebar'

// Import images
import octoberImage from "../../asset/October-Magzine.jpeg";
import septemberImage from "../../asset/sept-coverpg.jpg";
import augustImage from "../../asset/August_magzinE.webp";
import julyImage from "../../asset/july_magazine.jpg";
import juneImage from "../../asset/june-banner.jpg";

// Import PDF files
import octoberPdf from "../../asset/October-magazine.pdf";
import septemberPdf from "../../asset/September.pdf";
import augustPdf from "../../asset/August-magazine.pdf";
import julyPdf from "../../asset/july.pdf";
import junePdf from "../../asset/june.pdf";
import Sidebar from "../Sidebar/Sidebar";
import Title from "../Titleholder/Title";

const magazinee = [
  {
    img: octoberImage,
    title: "Empowering The Next Generation: A New Era in Agriculture",
    pdflink: octoberPdf,
    Pro_type: "Magazine",
    Published_by: "Tech Vist IT Pvt Ltd",
    Publication_M: "October",
    Mode: "Print & Online",
  },
  {
    img: septemberImage,
    title: "Empowering Agriculture Ganesha's Blueprint for a Sustainable Future",
    pdflink: septemberPdf,
    Pro_type: "Magazine",
    Published_by: "Tech Vist IT Pvt Ltd",
    Publication_M: "September",
    Mode: "Print & Online",
  },
  {
    img: augustImage,
    title: "A Way To A Sustainable Future",
    pdflink: augustPdf,
    Pro_type: "Magazine",
    Published_by: "Tech Vist IT Pvt Ltd",
    Publication_M: "August",
    Mode: "Print & Online",
  },
  {
    img: julyImage,
    title: "Ecrop Your Personal Farming Companion",
    pdflink: julyPdf,
    Pro_type: "Magazine",
    Published_by: "Tech Vist IT Pvt Ltd",
    Publication_M: "July",
    Mode: "Print & Online",
  },
  {
    img: juneImage,
    title: "Revolutionizing Agriculture The Future Of Farming",
    pdflink: junePdf,
    Pro_type: "Magazine",
    Published_by: "Tech Vist IT Pvt Ltd",
    Publication_M: "June",
    Mode: "Print & Online",
  },
  {
    img: juneImage,
    title: "Revolutionizing Agriculture The Future Of Farming",
    pdflink: junePdf,
    Pro_type: "Magazine",
    Published_by: "Tech Vist IT Pvt Ltd",
    Publication_M: "November",
    Mode: "Print & Online",
  },
];

// Define responsive breakpoints for Carousel
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Magazine = () => {
  return (
    <>
      <div className="container carousel-container">
         {/*<Title title="e-CROPTODAY Magazine" />
        <Carousel
          responsive={responsive}
          autoPlay
          autoPlaySpeed={3000} // Adjust speed as needed
          infinite // Enable looping
          swipeable // Allow swiping on touch devices
          draggable // Allow dragging
          keyBoardControl // Allow navigation with keyboard
          showDots // Show navigation dots
        >
          {magazinee.map((maga, index) => (
            <div className="carousel-item1" key={index}>
              <img src={maga.img} alt={`Slide ${index + 1}`} />
              <div className="overlay">
                <div className="overlay-content">
                  <h4>{maga.title}</h4>
                  <p><span>Product Type</span>: {maga.Pro_type}</p>
                  <p><span>Published By</span>: {maga.Published_by}</p>
                  <p><span>Publication Month</span>: {maga.Publication_M}</p>
                  <p><span>Mode</span>: {maga.Mode}</p>
                </div>
                <button
                  className="view-pdf-button"
                  onClick={() => { window.open(maga.pdflink, '_blank'); }}
                >
                  View PDF
                </button>
              </div>
            </div>
          ))}
        </Carousel> */}
           {/* You can use any container class here */}
      <div className="row">
      <Title title="Featured Blogs" />

        <div className='col-lg-8'>
        <Blog columnClass="col-lg-12" viewMore={true} />{/* 1 column layout */}
        </div>
        <div className="col-lg-4">
          <Sidebar/>
        </div>
      </div>
    </div>

    </>
  );
};

export default Magazine;
